import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Layout from '../../../components/Layout';
import { historyMW } from '../../../helpers/routingHelpers';
import { useLazyGetClientTokenQuery, useLazyGetEncryptedVaultIdQuery, usePayMutation } from '../../../services/braintree.api';
import { setOutOfStock, updateBasket } from '../../../store/basketSlice';
import { setCollectionTime } from '../../../store/sessionSlice';
import Pay from '../components/Pay';
import LoaderBlack from '../../../img/common/Loader_360x360.gif';
import { postMessageWithAwait } from '../../../helpers/appHelpers';

const Payment = ({ 
  showSpinner,
  handleCompletePayment,
  grandTotal,
  customer,
  setPaymentErrorMessage,
  setShowSpinnner,
  optIn,
  paymentTaken,
  setErrorStatus,
  setDisablePaymentBackButton,
  disablePaymentBackButton
}) => {
  const { restaurant, user, collectionTime, inApp } = useSelector(state => state.session);
  const { isDelivery } = useSelector(state => state.basket);
  const [storeCardInVault, setStoreCardInVault] = useState(false);
  const [optOut, setOptOut] = useState(false);
  const [currentlySubmittingPayment, setCurrentlySubmittingPayment] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const [supportedPaymentMethods, setSupportedPaymentMethods] = useState(false);
  const [getEncryptedVaultId] = useLazyGetEncryptedVaultIdQuery();
  const [getClientToken] = useLazyGetClientTokenQuery();
  const [pay] = usePayMutation();

  const generatePaymentBody = (payload, paymentType) => {
    return {
      providerId: '1',
      optIn: !optOut || optIn,
      payment: {
        paymentMethodNonce: payload.nonce,
        storeCardInVault,
        grandTotal,
      },
      collectionTime: collectionTime !== '' ? collectionTime : null,
      isDelivery,
      paymentType,
      inApp,
      isMobile: window?.innerWidth < 768 ?? true
    };
  };

  useEffect(() => {
    const getPaymentMethods = async () => {
      if (inApp) {
        const { applePay, googlePay } = await postMessageWithAwait({ type: 'PAYMENT_METHODS_SUPPORTED' });
        setSupportedPaymentMethods({ applePay, googlePay });
      }
    };
    getPaymentMethods();
  }, []);

  const storeVaultId = async (vaultId) => {
    await getEncryptedVaultId({ vaultId });
  };

  const handleSubmitPaymentFail = (data) => {
    setDisablePaymentBackButton(false);
    if (!data) {
      setPaymentErrorMessage('We are having trouble contacting the payment provider, please try again later.');
      historyMW.replace('/payment/error', isDelivery, history);
      return;
    }

    const status = data.status;
    const statusDescription = data.statusDescription;

    if (status === 'REJECTED') {
      setErrorStatus(statusDescription);
      setPaymentErrorMessage('Unfortunately Uber can no longer fulfil this order. If payment has been taken, we have refunded you. Please try again or try Collection instead.');
      historyMW.replace('/payment/error', isDelivery, history);
    }
    else if (status === 'OUT_OF_STOCK') {
      dispatch(setOutOfStock(data.basket.basket));
    } else if (status === 'COLLECTION_TIME_NO_LONGER_AVAILABLE') {
      dispatch(setCollectionTime('passed'));
    } else {
      switch (statusDescription) {
        case 'Gateway Rejected: three_d_secure':
        case 'Payment Failed. Payment liability not shifted type…DSecureInfo: Present LiabilityShiftPossible: True':
          setPaymentErrorMessage('Authentication unsuccessful. Please try another form of payment.');
          break;
        case 'Payment Failed. Payment liability not shifted type: CreditCard ThreeDSecureInfo: Present LiabilityShiftPossible: True':
          setPaymentErrorMessage('Authentication unavailable. Please try another form of payment.');
          setCurrentlySubmittingPayment(false);
          setDisablePaymentBackButton(false);
          return;
        case 'Gateway Rejected: fraud':
          setPaymentErrorMessage('This card has been blocked due to detected fraudulent activity. Please try another form of payment.');
          break;
        case 'Gateway Rejected: cvv':
          setPaymentErrorMessage('Incorrect card details. Please try again.');
          break;
        case 'Could not redeem loyalty rewards':
          setPaymentErrorMessage('Oh no! Something went wrong and we have been unable to process your order. You will be issued a full refund. We’re so sorry about that, please try placing an order again later or contact the restaurant directly.');
          break;
        case 'Voucher mismatch':
          setPaymentErrorMessage('Oh no! A voucher applied to your basket is no longer valid. We have removed the voucher.');
          dispatch(updateBasket({ ...data.basket.basket, voucherExceptions: data.basket.voucherExceptions }));
          break;
        default:
          setPaymentErrorMessage('We can\'t process your payment, please try again later or use a different method.');
          break;
      }
      historyMW.replace('/payment/error', isDelivery, history);
    }
  };

  const handleBack = () => {
    historyMW.push('/checkout/details', isDelivery, history);
  };

  useEffect(() => {
    if (showSpinner) {
      window.scrollTo({ left: 0 }, { top: 0 });
    }
  }, [showSpinner]);

  return (
    <Layout
      title={paymentTaken ? 'WE ARE PROCESSING YOUR ORDER' : 'CHOOSE YOUR PAYMENT METHOD'}
      heroWidthHalf={true}
      containerBreakpoint="desktop"
      backButton={handleBack}
      isPayment
      noImage
      isBasket
      backgroundColour={inApp ? 'malbec-100' : 'transparent'}
      titleColour={inApp ? 'rose-100' : 'black'}
      sectionSize={`${inApp ? 'pt-6' : 'is-padless-top'} is-padless-bottom'`}
      disableBackButton={disablePaymentBackButton}
      customStyles='is-basket'
    >
      <div style={{
        width: '100%', display: 'grid', placeItems: 'center',
        paddingTop: `${inApp ? '10px' : '0px'}`
      }}>
        <div className='payment-container'>
          {
            showSpinner ?
              <img src={LoaderBlack} style={{ width: 64, height: 64 }} /> :
              <Pay
                handleNoClientToken={() => console.log('handleNoClientToken')}
                submitPaymentFail={handleSubmitPaymentFail}
                paymentSuccessful={handleCompletePayment}
                submitPayment={() => setCurrentlySubmittingPayment(true)}
                submitPaymentCancel={() => setCurrentlySubmittingPayment(false)}
                restaurant={restaurant}
                restaurantId={restaurant.id}
                user={user}
                grandTotal={grandTotal}
                storeCardInVault={storeCardInVault}
                setStoreCardInVault={setStoreCardInVault}
                storeVaultId={storeVaultId}
                optOut={optOut}
                setOptOut={setOptOut}
                generatePaymentBody={generatePaymentBody}
                currentlySubmittingPayment={currentlySubmittingPayment}
                setCurrentlySubmittingPayment={setCurrentlySubmittingPayment}
                getClientToken={getClientToken}
                pay={pay}
                customer={customer}
                optIn={optIn}
                supportedPaymentMethods={supportedPaymentMethods}
                setDisablePaymentBackButton={setDisablePaymentBackButton}
              />
          }
        </div>
      </div>
      {/* The following is required to preload the spinner. */}
      <img src={LoaderBlack} style={{ width: 0, height: 0 }} />
    </Layout>
  );
};

export default Payment;
